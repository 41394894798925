@font-face {
  font-family: 'HKGrotesk-Regular';
  src: url('/fonts/HKGrotesk-Regular.eot'); /* IE9 Compat Modes */
  src: local('HKGrotesk-Regular'), local('HKGrotesk-Regular'),
  url('/fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/HKGrotesk-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/HKGrotesk-Regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/HKGrotesk-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'HKGrotesk-Bold';
  src: url('/fonts/HKGrotesk-Bold.eot'); /* IE9 Compat Modes */
  src: local('HKGrotesk-Bold'), local('HKGrotesk-Bold'),
  url('/fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/HKGrotesk-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/HKGrotesk-Bold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/HKGrotesk-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: "HKGrotesk-Regular", sans-serif;
}

a {
  cursor: pointer;
  color: #a62324;
}

a:hover {
  text-decoration: underline;
}

h2 {
  color: 000;
  margin: 0px;
  padding: 0px;
}

h4 {
  color: 000;
  margin: 0px;
  padding: 0px;
}

/** FORM **/

form {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

form h2 {
  font-size: 1.8em;
  margin: 0px auto 30px auto;
  color: 000;
  font-weight: bold;
  padding: 0 20px;
  text-align: center;
}

form .inputs {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

form input {
  font-size: 1em;
  border: .5px solid #000;
  border-radius: 30px;
  padding: 21px;
  margin: 7px auto;
  width: 100%;
  box-sizing: content-box;
  font-family: "HKGrotesk-Regular", sans-serif;
}

select {
  font-size: 1em;
  width: 100%;
  padding: 21px;
  margin: 7px 0px;
  border: .5px solid #000;
  border-radius: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
  background: url("/images/dropdown.png") 99% / 45px no-repeat #FFF;
  font-family: "HKGrotesk-Regular", sans-serif;
  background-position: right 10px center;
}

form textarea {
  font-size: 1em;
  width: 100%;
  padding: 21px;
  margin: 7px 0px;
  border: .5px solid #000;
  border-radius: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
  font-family: "HKGrotesk-Regular", sans-serif;
}

form select::-ms-expand { 
  display: none; /* remove default arrow on ie10 and ie11 */
}

form .checkbox {
  margin: 0 auto;
  margin-top: 20px;
  font-family: "HKGrotesk-Regular", sans-serif;  
  text-align: center;
}

form .checkbox input[type=checkbox] {
  border: none;
  margin: 0px;
  padding: 0px;
  height: 25px;
  width: 25px;
}

form .checkbox label {  
  font-size: 1.1em;
  margin-top: 0px;
  display: block;
}

form .radio {
  text-align: center;
}

form .radio input[type=radio] {
  border: none;
  margin: 0px;
  padding: 0px;
  height: 25px;
  width: 25px;
}

form .radio label {  
  font-size: 1.1em;
  margin-top: 0px;
  display: block;
}

form .required {
  margin: 15px auto 0px auto;
  text-align: center;
}

 form .error {
  color: #a62324;
  margin-top: 50px;
  font-size: 1.1em;
  text-align: center;
 }

 .subform {
   padding: 20px 0;
   color: 000;
 }

 .submit {
   margin-top: 50px;
 }

 .confirmation {
  text-align: center;
  color: #3a953c;
  font-size: 1.2em;
 }

@media (min-width: 1200px) {

  form {
    width: 1200px;
  }

  form .inputs {
    width: 50%;
    margin: 0 auto;
  }
  
  form .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  form .checkbox input[type=checkbox] {
    border: none;
    margin: 0px;
    padding: 0px;
    height: 25px;
    width: 25px;
  }

  form .checkbox label {
    margin-top: 10px;
    margin-left: 10px;
  }
}

/** TABLE */

.table th {
  font-weight: bold;
  color: 000;
}

.table td {
  vertical-align: middle;
}

.active {
  color: #3a953c;  
  text-align: center;
}

.inactive {
  color: #a62324;
  text-align: center;
}

/** CHIPS (FILTERS) */

.chips {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}

.chip {
  margin: 0 5px !important;
  margin-top: 10px !important;
}

.chip_selected {
  margin: 0 5px !important;
  margin-top: 10px !important;
  background: 000 !important;
  color: #fff !important;
}

.chip_clear {
  margin: 0 5px !important;
  background: #a62324 !important;
  color: #fff !important;
}

.message {
  font-size: 1.2em;
  text-align: center;
}

/** ACCORDIONS */

.accordion__title {
    font-size: 1.8em;
    color: 000;
    font-weight: bold;
    padding: 10px 0px;
    margin-bottom: 20px;
    text-align: center;
  }

.accordion__heading_1 {
  background-color: #E8EBE2;
  width: 100%;  
  text-align: center;
}

.accordion__heading_2 {
  background-color: #FFF;
  width: 100%;  
  text-align: center;
}



.accordion__heading_isskipped {
  background-color: #fdc230;
  width: 100%;  
  text-align: center;
}

.accordion__heading_isskipped .accordion__button {
  color: #000;
  border-top: 1px solid #fff;   
}

.accordion__heading_isskipped .level_inputs_title {
  color: #000;
}

.accordion__heading_penalty_1 {
  background-color: #a62324;
  width: 100%;  
  text-align: center;
}

.accordion__heading_penalty_1 .accordion__button {
  color: #fff;
  border-top: 1px solid #fff;   
}

.accordion__heading_penalty_1 .level_inputs_title {
  color: #fff;
}

.accordion__heading_penalty_2 {
  background-color: #000;
  width: 100%;  
  text-align: center;
}

.accordion__heading_penalty_2 .accordion__button {
  color: #fff;
  border-top: 1px solid #fff;   
}

.accordion__heading_penalty_2 .level_inputs {
  color: #fff;
}


.accordion__button {
  padding: 30px 0 !important;
  margin: 0 auto;
  cursor: pointer;
  color: #a62324;    
  font-size: 1.50em;
  font-weight: bold;
  text-transform: uppercase;  
  text-align: center;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 5px;
    width: 5px;
    margin-right: 12px;
    margin-bottom: 5px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

.accordion__mark {
  color: #a62324;
  font-style: italic;
  padding-top: 10px;
}

.accordion__panel {
  padding: 0px;
  margin: 0px;
}

@media (min-width: 1200px) {
  .accordion__button {
    width: 100%;
  }

  .accordion__button:before {
    height: 10px;
    width: 10px;
  }
}

/** DETAILS */

.details_section {
  font-size: 1.2em;
}

.detail_item {
  margin: 15px auto;
}

.detail_attribute {
font-weight: bold;
color: 000;
}

.detail_value {
}

.detail_button {
  padding-top: 20px;
}

@media (min-width: 1200px) {
  .detail_item {
    display: flex;
    width: 1200px;
  }

  .detail_attribute {
    text-align: right;
    padding-right: 20px;
    width: 500px;
  }

  .detail_value {
    text-align: left;
    width: 500px;
  }
}

/** BUTTONS */

.btn_buttons {
  padding-top: 50px;
}

.btn_edit {
  padding-bottom: 10px;
}

.btn_cancel {
  padding-bottom: 10px;
}

.btn_generate {
  padding-bottom: 10px;
}

.btn_back {
  padding: 0;
}

.btn_generate {
  padding: 0;
}

.btn_add {
  padding-top: 40px;
}
.btn_delete {
  padding: 0;
}